










































import Component, {mixins} from 'vue-class-component';
import { placeModule, paymentModule, reservationModule } from '@/store';
import dataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { filter } from 'lodash';

@Component<Place>({
  components: {
    'thumbnail-list': () => import('@/components/common/ThumbnailList.vue'),
    'main-info': () => import('@/components/place/MainInfo.vue'),
    'product-description': () => import('@/components/product/ProductDescription.vue'),
    'product-config': () => import('@/components/product/ProductConfig.vue'),
    'reservation-info': () => import('@/components/product/ReservationInfo.vue'),
    'reservation-notice': () => import('@/components/product/ReservationNotice.vue'),
    'place-info': () => import('@/components/common/PlaceInfo.vue'),
    'use-info': () => import('@/components/place/UseInfo.vue'),
    'product-list': () => import('@/components/place/ProductList.vue'),
    'place-feature': () => import('@/components/common/PlaceFeature.vue'),
    'to-reserve': () => import('@/components/common/ToReserve.vue'),
    'thumbnail-detail-modal': () => import('@/pages/modal/ThumbnailDetailModal.vue'),
    'thumbnail-list-modal': () => import('@/pages/modal/ThumbnailListModal.vue'),
    'place-map-modal': () => import('@/pages/modal/PlaceMapModal.vue'),
    'to-reserve-modal': () => import('@/pages/modal/ToReserveModal.vue'),
    // 'reservation-complete': () => import('@/pages/modal/ReservationComplete.vue')
  },
  metaInfo() {
    return {
      title: this.place.name + ' | POING',
      meta: [
        {
          name: 'keywords',
          vmid: 'keywords',
          content: '포잉, POING,' + this.place.name
        },
        {
          name: 'description',
          vmid: 'description',
          content: this.place.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: window.location.href
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.place.name + ' | POING'
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.place.description
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.place.image && this.place.image.original.url
        }
      ]
    }
  }
})
export default class Place extends mixins(dataFormat) {
  @Prop()
  public placeId!: any;

  public isScroll: boolean = false;
  public visitedList: any = [];
  public loadingStatus: boolean = false;

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadingStart();
      vm.visitedCookies();
      vm.$modal.hide('placeMap');
      // placeModule.fetchPlace(vm.placeId).then((res: any) => {
      //   if (!res.status) {
      //     vm.$q.notify(res.error.message);
      //     if (from.name) vm.$router.go(-1);
      //     else vm.$router.push({ path: '/' });
      //   }
      // });
    });
  }

  public beforeRouteLeave(to, from, next) {
    reservationModule.resetSchedule();
    next();
  }

  public eventViewItem(data) {
    const eventName = 'view_item';
    const eventParams: any = {
      type: 'place',
      id: data.id.toString(),
      name: data.name
    }
    // firebase.analytics().logEvent(eventName, eventParams);
  }

  public mounted() {
    this.loadingStart();
    placeModule.fetchPlaceInfoData(this.placeId).then(() => {
      this.eventViewItem(this.place);
      this.loadingEnd();
    });
    placeModule.fetchPlaceImage(this.placeId);
    placeModule.fetchPlaceMenu(this.placeId);
    placeModule.fetchPlaceProduct(this.placeId);
    // 타임슬롯 api 2번 호출 해서 placeId만으로 호출하는 부분 주석처리
    // placeModule.fetchPlaceTime(this.placeId);
    if (this.$route.params.schedule_date) {
      EventBus.$emit('updateScheduleDate', this.$route.params.schedule_date);
    }
    this.initReview(this.placeId);
  }

  public beforeDestroy() {
    this.loadingEnd();
    document.addEventListener('scroll', this.handleScroll);
  }
  public loadingStart() {
    EventBus.$emit('loadingStatus', true);
    this.loadingStatus = true;
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public initReview(id) {
    const params = {
      type: 'realtime',
      offset: 1,
      limit: 3,
      place_id: id,
      time: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    // reviewModule.fetchUserReview(params);
  }

  public normalReserve() {
    // if (auth.getAccessUserId() === '') {
    //   this.$modal.show('login');
    // } else {
    //   this.$modal.show('toReserve');
    // }
  }

  public handleScroll() {
    const el = document.querySelector('.place-detail-wrap') as HTMLDivElement;
    if (el) {
      const bottom = el.scrollHeight;
      let barHeight = (window.innerWidth * 0.75) - 53;
      if (window.innerWidth > 600) {
        barHeight = 500;
      }
      if (window.scrollY > barHeight) {
        if (window.scrollY > (bottom - window.innerHeight - 1)) {
          el.classList.add('scroll-bottom');
        } else {
          el.classList.remove('scroll-bottom');
        }
        return this.isScroll = true;
      }
      return this.isScroll = false;
    }
  }

  public created() {
    document.addEventListener('scroll', this.handleScroll);
  }

  public visitedCookies() {
    const now = new Date().getTime();
    const after1Month = now + 86400000 * 30;
    if (this.$cookies.get('poingVisited')) {
      this.visitedList = JSON.parse(this.$cookies.get('poingVisited'));
      this.visitedList = filter(this.visitedList, (i) =>
        i.place_id !== this.place.id && i.visited_at > now
      )
    }
    this.visitedList.unshift({
      place_id: this.place.id,
      visited_at: after1Month
    });
    const visited = JSON.stringify(this.visitedList);
    this.$cookies.set('poingVisited', visited);
  }

  public back() {
    if (window.history.length === 1) {
      this.$router.push({path: '/'});
    } else {
      this.$router.go(-1);
    }
  }

  public customLink() {
    if (this.place.custom_button.type === 'ticket') this.$router.push({ path: this.place.custom_button.scheme_web });
    else window.open(this.place.custom_button.scheme_web, '_blank');
  }

  get place(): any {
    return placeModule.placeInfoData;
  }
  get placeImage() {
    return placeModule.placeImage;
  }
  get placeMenu() {
    return placeModule.placeMenu;
  }
  get productList() {
    return placeModule.placeProduct;
  }
  get placeTime() {
    return placeModule.placeTime;
  }
  // get reviews() {
  //   return reviewModule.userReview;
  // }
}
